
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <breadcumb :page="'Settings'" :folder="'Pages'" />

    <div class="card text-left">
      <div class="card-body">
        <b-tabs
          active-nav-item-class="nav nav-tabs"
          content-class="mt-3"
        >
          <b-tab title="Finishes" active>

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-finish>Add new</b-button>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="finishes" :fields="finishes_columns">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditProductFinishesModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteProductFinishesModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>

          <b-tab title="Locations" >

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-location>Add new</b-button>
              </div>
            </div>

            <b-table striped hover :items="locations" :fields="location_columns">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">

                </b-dropdown>

              </template>
            </b-table>
          </b-tab>
          <b-tab title="Lead Sources">

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-lead-source>Add new</b-button>
              </div>
            </div>

            <b-table striped hover :items="lead_sources" :fields="lead_source_columns">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditLeadSourceModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteLeadSourceModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Quick Books " >

            <div class="container">
              <form action="#" class="form-horizontal" v-on:submit.prevent="saveQBsettingsData"  method="post">
                <input type="hidden" name="_token" />
                <div class="form-body">

                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label ">Refresh Token:</label> <br/>
                        <div >
                          <input class="form-control" type="text"  v-model="qb_settings.refreshToken" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label ">Client Secret:</label> <br/>
                        <div >
                          <input class="form-control" type="text"  v-model="qb_settings.clientSecret" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label ">Access Token:</label> <br/>
                        <div >
                          <textarea style="width: 100%" rows="8" class="form-control" type="text"  v-model="qb_settings.accessToken" ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label ">Company Id:</label> <br/>
                        <div >
                          <input class="form-control" type="text"  v-model="qb_settings.companyId" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" >
                    <div class="col-md-12">
                      <button class="btn btn-success" type="submit">Save</button>
                    </div>
                  </div>

                </div>

              </form>
            </div>



          </b-tab>

          <b-tab title="Commissions" >

            <div class="container">
              <form action="#" class="form-horizontal" v-on:submit.prevent="saveCommissionSettingsData"  method="post">
                <input type="hidden" name="_token" />
                <div class="form-body">

                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label "><strong>Status:</strong></label> <br/>
                        <div >
                          <label for="commission_status_active">
                            <input id="commission_status_active" type="radio" v-model="commission.status" name="commission_status" value="Active"> Active
                          </label> <br/>
                          <label for="commission_status_inactive">
                            <input id="commission_status_inactive" type="radio" v-model="commission.status" name="commission_status" value="Inactive"> Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label "><strong>Commission Type:</strong></label> <br/>
                        <div >
                          <select class="form-control"  v-model="commission.commissionType">
                             <option value="FIXED">Amount</option>
                             <option value="PERCENTAGE">Percentage</option>
                          </select>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="control-label "><strong>Value:</strong></label> <br/>
                        <div >
                          <input class="form-control" type="number"  v-model="commission.amount" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" >
                    <div class="col-md-12">
                      <button class="btn btn-success" type="submit">Save</button>
                    </div>
                  </div>

                </div>

              </form>
            </div>



          </b-tab>

          <b-tab title="Delivery Fees" >

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-delivery-fee>Add new</b-button>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="delivery_fees" :fields="delivery_fee_columns">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openDeleteDeliveryFeeModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>

          <b-tab title="Roles and Permissions">

            <div class="row">
              <div class="col-md-12">
                <a href="/app/pages/createPermissions" class="btn btn-danger float-right"><i class="fa fa-plus"></i> Add new</a>
              </div>
            </div>
            <b-table striped hover :items="roles" :fields="headers_roles">

              <template v-slot:cell(imgUrl)="data">
                <img style="width: 100px; height: 70px;" :src="data.value" class="img img-responsive img-thumbnail" />
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="navigateEditRole(data.item.id)">Edit</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>

        </b-tabs>
      </div>
    </div>

    <b-modal id="add-delivery-fee" title="Add Delivery Fee" @cancel="true" @ok="saveDeliveryFeeData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">County:</label> <br/>
                <div >
                  <select class="form-control" v-model="delivery_fee.county">
                    <option :value="county.name" v-for="county in counties">{{ county.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Delivery Fee:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="delivery_fee.deliveryFee" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>

    <b-modal id="add-location" title="Add Location" @cancel="true" @ok="saveLocationData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Location:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="location.locationName" />
                </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">County:</label> <br/>
                <div >
                  <select class="form-control" v-model="location.county">
                    <option :value="county.name" v-for="county in counties">{{ county.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>


        </div>

      </form>
    </b-modal>

    <b-modal id="add-finish" title="Add Product Finishes" @cancel="true" @ok="validateBeforeSubmitNewProductFinishes"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product_finishes.finishName" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>
    <b-modal id="edit-finishes" title="Edit Product Finishes" @cancel="true" @ok="validateBeforeSubmitEditProductFinishes"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">


          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product_finishes.finishName" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    </b-modal>

    <b-modal id="add-lead-source" title="Add Lead Source" @cancel="true" @ok="addLeadSourceData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead_source.name" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>
    <b-modal id="edit-lead-source" title="Edit Lead Source" @cancel="true" @ok="editLeadSourceData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="lead_source.name" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </b-modal>

  </div>
</template>
<script>

  import {counties} from "@/data/counties";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapGetters, mapActions } from "vuex";

export default {
  display: "Transitions",
  order: 7,

  data() {
    return {
      counties,
      isLoading: false,
      fullPage: true,
      selected:[],
      product_finishes:{
        id:0,
        finishName:"",
      },
      lead_source:{
        id:0,
        name:"",
      },
      roles:[],
      headers_roles:['role','Options'],
      qb_settings:{
        id:0,
        refreshToken:"",
        clientId:"",
        clientSecret:"",
        companyId:"",
        accessToken:""
      },
      commission:{
        id:0,
        commissionType:"",
        amount:"",
        status:""
      },
      delivery_fee:{
        id:0,
        county:"",
        deliveryFee:""
      },
      location:{
        id:0,
        locationName:"",
        county:""
      },
      finishes:[],
      locations:[],
      lead_sources:[],
      finishes_columns:["id","finishName","options"],
      delivery_fee_columns:["id","county","deliveryFee","options"],
      location_columns:["id","locationName","county","options"],
      lead_source_columns:["id","name","options"],
      delivery_fees:[]
    };
  },
  components: {
    Loading
  },
  computed: {
    ...mapGetters(["loading","error"])
  },

  mounted (){
      this.getProductFinishesData();
      this.getQBSettingsData();
      this.getCommissionsSettingsData();
      this.getDeliveryFeesData();
      this.getRolesData();
      this.getLocationsData();
      this.getLeadSourcesData();
  },
  methods:{
    ...mapActions(["getRoles","saveDeliveryFees","deleteDeliveryFees","getDeliveryFees","getSalesCommission","saveSalesCommission","editProductFinishes","saveProductFinishes","deleteProductFinishes","getProductFinishes","getLocations","createLocation","saveQBSettings","getQBSettings","addLeadSource","editLeadSource","deleteLeadSource","getLeadSources"]),

    navigateEditRole(role_id){
      this.$router.push("/app/pages/editRole/"+role_id);
    },

    getRolesData(){
      let self = this;
      this.getRoles()
              .then((roles) => {
                self.roles = roles;
              })
              .catch(error => {

              })
    },
    openEditProductFinishesModal(product_finishes){

      this.product_finishes = product_finishes;
      this.$bvModal.show('edit-finishes');

    },

    openEditLeadSourceModal(lead_source){
      this.lead_source = lead_source;
      this.$bvModal.show('edit-lead-source');
    },

    saveCommissionSettingsData(){
      this.isLoading = true;

      let self = this;

      this.saveSalesCommission(this.commission).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Commissions settings saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getCommissionsSettingsData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    saveQBsettingsData(){
      this.isLoading = true;

      let self = this;

      this.saveQBSettings(this.qb_settings).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("QB settings saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getQBSettingsData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    openDeleteDeliveryFeeModal(delivery_fee){

      this.delivery_fee = delivery_fee;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete delivery fee for '+this.delivery_fee.county +' ?')
              .then(value => {

                if(value){
                  self.confirmDeleteDeliveryFee();
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    openDeleteProductFinishesModal(product_finishes){

      this.product_finishes = product_finishes;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.product_finishes.finishName +' from system')
              .then(value => {

                if(value){
                  self.confirmDelete();
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    openDeleteLeadSourceModal(lead_source){

      this.lead_source = lead_source;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.lead_source.name +' from system')
          .then(value => {

            if(value){
              self.confirmDeleteLeadSource();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },

    confirmDeleteDeliveryFee(){
      this.isLoading = true;

      let self = this;

      this.deleteDeliveryFees(this.delivery_fee).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Delivery fee deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getDeliveryFeesData();

      })
              .catch(error => {

                self.isLoading = false;


                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })

    },

    confirmDelete(){
      this.isLoading = true;

      let self = this;

      this.deleteProductFinishes(this.product_finishes).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product finishes deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductFinishesData();

      })
              .catch(error => {

                self.isLoading = false;


                console.log(error);

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })

    },
    confirmDeleteLeadSource(){
      this.isLoading = true;

      let self = this;

      this.deleteLeadSource(this.lead_source).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead source deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadSourcesData();

      })
          .catch(error => {

            self.isLoading = false;


            console.log(error);

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })

    },

    getCommissionsSettingsData(){
      let self = this;
      this.getSalesCommission()
              .then(function (commission) {

                self.commission = commission;

              })
              .catch(function (error) {



              })
    },

    getQBSettingsData(){
      let self = this;
      this.getQBSettings()
              .then(function (qb_settings) {

                self.qb_settings = qb_settings;

              })
              .catch(function (error) {



              })
    },

    getDeliveryFeesData(){
      let self = this;
      this.getDeliveryFees()
              .then(function (delivery_fees) {

                self.delivery_fees = delivery_fees;

              })
              .catch(function (error) {



              })
    },

    getProductFinishesData(){
      let self = this;
      this.getProductFinishes()
              .then(function (finishes) {

                self.finishes = finishes;

              })
              .catch(function (error) {



              })
    },
    getLeadSourcesData(){
      let self = this;
      this.getLeadSources()
          .then(function (lead_sources) {
            self.lead_sources = lead_sources;
          })
          .catch(function (error) {

          })
    },

    getLocationsData(){
      let self = this;
      this.getLocations()
              .then(function (locations) {

                self.locations = locations;

              })
              .catch(function (error) {



              })
    },

    saveLocationData(){
      this.isLoading = true;

      let self = this;

      this.createLocation(this.location).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Location saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLocationsData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },
    addLeadSourceData(){
      this.isLoading = true;

      let self = this;

      this.addLeadSource(this.lead_source).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead source saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadSourcesData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          })
    },
    editLeadSourceData(){
      this.isLoading = true;

      let self = this;

      this.editLeadSource(this.lead_source).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lead source saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLeadSourcesData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          })
    },


    saveDeliveryFeeData(){
      this.isLoading = true;

      let self = this;

      this.saveDeliveryFees(this.delivery_fee).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Delivery fee saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getDeliveryFeesData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },

    validateBeforeSubmitNewProductFinishes(){
      this.isLoading = true;

      let self = this;

      this.saveProductFinishes(this.product_finishes).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product finishes saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductFinishesData();

      })
              .catch(error => {

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
    },
    validateBeforeSubmitEditProductFinishes(){
      this.isLoading = true;

      let self = this;

      this.editProductFinishes(this.product_finishes).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product finishes saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProductFinishesData();

      })
              .catch(error => {

                console.log(error);

                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                /*if( typeof error.response.status != "undefined"){
                   if(error.response.status == 401){
                     location.replace("/app/sessions/signIn");
                   }

                }
                else{




                }*/
              })
    },
  }
};
</script>
